import React from "react";
import { Link, useNavigate } from "react-router-dom";
// styles
import "../styles/HeadMenu.css";
import "../styles/page.css";
// project files
import withRouter, { getRoutePath, getRouteName } from "../utils/withRouter";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { Item, selectItems, showCart, selectStatus, fetchCart, fetchCartAsync, changeQuantityCart } from "../features/cart/cartSlice";
// import LoginButton from "../features/user/LoginButton";
import { selectUsername, selectUserStatus, logoutAsync, refreshLogin, logout } from "../features/user/userSlice";
import { selectCategoryStatus, selectCategoryList, getCategoryListAsync } from "../features/category/categorySlice";

// libs
import { isMobile } from "react-device-detect";
// antd
import { Menu, Dropdown, Button, Space, Row, Col, Avatar, Badge } from "antd";
import { LoginOutlined, LogoutOutlined, UserOutlined, ShoppingCartOutlined } from "@ant-design/icons";
const { SubMenu } = Menu;

export default function HeadMenu(props: any) {
  console.log("HeadMenu start");
  const position = props.position;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const username = useAppSelector(selectUsername);
  const userstatus = useAppSelector(selectUserStatus);
  const cartItems = useAppSelector(selectItems);
  const carstatus = useAppSelector(selectStatus);
  const categoryStatus = useAppSelector(selectCategoryStatus);
  const categoryList = useAppSelector(selectCategoryList);

  if (userstatus === "idle") {
    console.log("refreshLogin");
    dispatch(refreshLogin());
  } else if (userstatus === "complete") {
    if (carstatus === "idle") {
      if (username === null) {
        dispatch(fetchCart());
      } else {
        dispatch(fetchCartAsync());
      }
    } else if (carstatus === "failed") {
      dispatch(logout());
    }
  }
  if (categoryStatus === "idle") {
    console.log("category");
    dispatch(getCategoryListAsync());
  }

  const handleMenuClick = (e) => {
    // let path = getRoutePath(e.key);
    console.log(e);
    if (e.key === "logout") {
      dispatch(logoutAsync());
      dispatch(fetchCart());
      return;
    } else if (e.key === "cart") {
      dispatch(showCart(true));
      return;
    }
    navigate(e.key);
  };

  console.log("HeadMenu render", props.position);
  const current = props.position;
  var padding = "90px";
  if (isMobile) {
    padding = "0";
  }

  const generateLoginMenu = () => {
    if (username === null) {
      return (
        <Menu.Item key={getRoutePath("login")} icon={<LoginOutlined />}>
          {getRouteName("login")}
        </Menu.Item>
      );
    } else {
      return (
        <Menu.SubMenu key="user" icon={<UserOutlined style={{ fontSize: "20px" }} />}>
          <Menu.Item key="logout" icon={<LogoutOutlined />}>
            Log Out
          </Menu.Item>
        </Menu.SubMenu>
      );
    }
  };

  return (
    // textAlign: "center"
    <div style={{ paddingLeft: padding, paddingRight: padding }}>
      <div className=" hlayout_center">
        <Link to="/">
          <div className="logoDiv">
            <img src="/imgs/logo.webp" className="logoImg" />
          </div>
        </Link>
        <div> </div>
        <div className="menuDiv">
          {/* <ul><li>12</li><li>23</li></ul> */}
          {/* <span> dfd</span>*/}
          <Menu className="head-menu" theme="dark" style={{ background: "transparent" }} onClick={handleMenuClick} selectedKeys={[current]} mode="horizontal">
            <Menu.Item key={getRoutePath("home")}>{getRouteName("home")}</Menu.Item>
            <Menu.SubMenu key={getRoutePath("product")} title={getRouteName("product")}>
              {categoryList.map((item,index)=>{
                return (<Menu.Item key={`/product/${item.id}`}>{item.name}</Menu.Item>);
              })}
              <Menu.Item key={getRoutePath("bestseller_All")}>{getRouteName("bestseller_All")}</Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key={getRoutePath("onsale_All")}>{getRouteName("onsale_All")}</Menu.Item>
            <Menu.Item key={getRoutePath("contactUs")}>{getRouteName("contactUs")}</Menu.Item>
            {/* <Menu.Item key={getRoutePath("login")} icon={<LoginOutlined />}>
              {getRouteName("login")}
            </Menu.Item> */}
            {generateLoginMenu()}
            {/* icon={<ShoppingCartOutlined style={{ fontSize: "28px" }} />} */}
            <Menu.Item key="cart">
              <Badge size="default" count={cartItems.length}>
                <Avatar shape="square" size="large" style={{ backgroundColor: "transparent" }} icon={<ShoppingCartOutlined style={{ fontSize: "28px" }} />} />
              </Badge>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </div>
  );
}
