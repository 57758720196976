import { createAsyncThunk, createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import ApiService from "../../services";
import _ from "lodash";
import cookie from "react-cookies";

export interface Item {
  id: number;
  product: number;
  created_time: string;
  update_time: string;
  name: string;
  price: number;
  realprice: number;
  cover: string;
  quantity: number;
  quantityMax: number;
  selected: boolean;
}

export interface Detail {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
}

export interface Address {
  addrStr: string;
  addrJson: string;
}

export interface CartState {
  status: "idle" | "loading" | "failed" | "complete";
  order_id: number| null;
  items: Array<Item>;
  amount: number;
  isShow: boolean;
  details: Detail| null;
  address: Address| null;
}

const initialState: CartState = {
  status: "idle",
  order_id: null,
  items: [],
  amount: 0,
  isShow: false,
  details: null,
  address: null,
};

export const fetchCartAsync = createAsyncThunk("cart/fetchCartAsync", async () => {
  const response = await ApiService.getCart();
  return response.data;
});
export const addCartAsync = createAsyncThunk("cart/addCartAsync", async (item: any) => {
  const response = await ApiService.addCartItem(item);
  return response.data;
});
export const changeCartAsync = createAsyncThunk("cart/changeCartAsync", async (data: any) => {
  const response = await ApiService.changeCartItem(data.cartid, data.data);
  return response.data;
});
export const deleteCartAsync = createAsyncThunk("cart/deleteCartAsync", async (cartid: any) => {
  const response = await ApiService.deleteCartItem(cartid);
  return cartid;
});

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    showCart: (state, action: PayloadAction<boolean>) => {
      console.log("showCart", action.payload);
      state.isShow = action.payload;
    },
    fetchCart: (state) => {
      // var _ = require('lodash');
      // if(current(state).items.length === 0)
      let init = initCart();
      if(init==null){
        state.items = [];
      }else{
        state.items = init;
      }      
      state.amount = amount(current(state).items);
      state.status = "complete";
      console.log(current(state).items);
    },
    emtyCart: (state) => {
      state.items = [];
      state.order_id = null;
      state.amount = 0;
      state.status = "complete";
      cookie.remove("cart");
    },
    addToCart: (state, action: PayloadAction<Item>) => {
      console.log("addToCart", action.payload);
      let items: any = _.cloneDeep(current(state).items);
      // let items: any = {...current(state).items};
      updateItem("add", action.payload, items);
      state.items = items;
      state.amount = amount(current(state).items);
      console.log("addToCart", current(state).items);
    },
    removeFromCart: (state, action: PayloadAction<Item>) => {
      let items: any = _.cloneDeep(current(state).items);
      updateItem("remove", action.payload, items);
      state.items = items;
      state.amount = amount(current(state).items);
    },
    changeQuantityCart: (state, action: PayloadAction<Item>) => {
      console.log("change", action.payload);
      let items: any = _.cloneDeep(current(state).items);
      updateItem("change", action.payload, items);
      state.items = items;
      state.amount = amount(current(state).items);
    },
    setDetails: (state, action: PayloadAction<Detail>) => {
      console.log("setDetails", action.payload);      
      state.details = action.payload;
      console.log("setDetails", current(state));
    },
    setAddr: (state, action: PayloadAction<Address>) => {
      console.log("setAddr", action.payload);      
      state.address = action.payload;
      console.log("setAddr", current(state));
    },
  },
  extraReducers: (builder) => {
    builder
      //fetchCartAsync
      .addCase(fetchCartAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCartAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchCartAsync.fulfilled, (state, action) => {
        console.log("fetchCartAsync", action.payload);
        state.items = generateItems(action.payload);
        state.amount = amount(current(state).items);
        state.status = "complete";
      })
      //addCartAsync
      .addCase(addCartAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCartAsync.fulfilled, (state, action) => {
        console.log("addCartAsync", action.payload);
        state.items = generateItems(action.payload);
        state.amount = amount(current(state).items);
        state.status = "complete";
      })
      //changeCartAsync
      .addCase(changeCartAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(changeCartAsync.fulfilled, (state, action) => {
        console.log("changeCartAsync", action.payload);
        let items: any = _.cloneDeep(current(state).items);
        state.items = changeItem(action.payload, items);
        state.amount = amount(current(state).items);
        state.status = "complete";
      })
      //deleteCartAsync
      .addCase(deleteCartAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCartAsync.fulfilled, (state, action) => {
        console.log("deleteCartAsync", action.payload);
        let items: any = _.cloneDeep(current(state).items);
        state.items = deleteItem(action.payload, items);
        state.amount = amount(current(state).items);
        state.status = "complete";
      });
  },
});

const initCart = () => {
  let cartString = cookie.load("cart");
  if(cartString){
    console.log("cartString", cartString);
    return cartString;
  }else{
    return null;
  }
  
};

const generateItems = (requestData: Array<any>) => {
  let items: Array<Item> = [];
  console.log("serverIP:", process.env.REACT_APP_SERVER);
  requestData.forEach((item, index) => {
    items.push({
      id: item.id,
      product: item.product,
      created_time: item.created_time,
      update_time: item.update_time,
      name: item.productInfo.name,
      price: item.productInfo.price,
      realprice: item.productInfo.realprice,
      cover: `${process.env.REACT_APP_SERVER}${item.productInfo.cover}`,
      quantity: item.quantity,
      quantityMax: item.productInfo.quantity,
      selected: item.selected,
    });
  });
  return items;
};

const changeItem = (requestData: any, items: Array<Item>) => {
  try {
    items.forEach((item, index) => {
      if (requestData.product === item.product) {
        item.quantity = requestData.quantity;
        item.selected = requestData.selected;
        item.created_time = requestData.created_time;
        item.update_time = requestData.update_time;
        throw new Error("EndIterative");
      }
    });
  } catch (e: any) {
    if (e.message != "EndIterative") throw e;
  }
  return items;
};

const deleteItem = (requestData: any, items: Array<Item>) => {
  let findIndex = -1;
  try {
    items.forEach((item, index) => {
      if (requestData === item.id) {
        findIndex = index;
        throw new Error("EndIterative");
      }
    });
  } catch (e: any) {
    if (e.message != "EndIterative") throw e;
  }
  if(findIndex !== -1){
    items.splice(findIndex, 1);
  }
  return items;
};

const amount = (items: Array<Item>) => {
  let amount = 0;
  items.forEach((item, index) => {
    amount += item.quantity * item.realprice;
  });
  return parseFloat(amount.toFixed(2));
};

const updateItem = (active: string, activeItem: Item, items: Array<Item>, isOnline = false) => {
  let findIndex = -1;
  try {
    items.forEach((item, index) => {
      if (activeItem.product === item.product) {
        findIndex = index;
        throw new Error("EndIterative");
      }
    });
  } catch (e: any) {
    if (e.message != "EndIterative") throw e;
  }

  if (findIndex !== -1) {
    let item = items[findIndex];
    let tempQuantity = item.quantity;
    switch (active) {
      case "add":
        tempQuantity += activeItem.quantity;
        break;
      case "remove":
        tempQuantity -= activeItem.quantity;
        break;
      case "change":
        tempQuantity = activeItem.quantity;
        break;
    }
    if (tempQuantity === 0) {
      // if (isOnline){
      //   ApiService.deleteCartItem(item.id)
      //       .then((response) => {
      //         console.log(response.data);
      //       })
      //       .catch(function (error) {
      //         console.log(error);
      //         return;
      //       });
      // }
      items.splice(findIndex, 1);
    } else {
      // if (isOnline) {
      //   ApiService.changeCartItem(item.id, { quantity: item.quantity, selected: item.selected })
      //       .then((response) => {
      //         console.log(response.data);
      //       })
      //       .catch(function (error) {
      //         console.log(error);
      //         return;
      //       });
      // }
      item.quantity = tempQuantity;
    }
  } else {
    switch (active) {
      case "add":
        // if (isOnline) {
        //   ApiService.addCartItem({ product:activeItem.product, quantity: activeItem.quantity, selected: activeItem.selected })
        //     .then((response) => {
        //       console.log(response.data);
        //     })
        //     .catch(function (error) {
        //       console.log(error);
        //       return;
        //     });
        // }
        items.push(activeItem);
        break;
    }
  }

  // 保存cookie
  cookie.save("cart", JSON.stringify(items));
};

export const { showCart, fetchCart, emtyCart, addToCart, removeFromCart, changeQuantityCart, setDetails, setAddr } = cartSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectItems = (state: RootState) => state.cart.items;
export const selectStatus = (state: RootState) => state.cart.status;
export const selectAmount = (state: RootState) => state.cart.amount;
export const selectIsShow = (state: RootState) => state.cart.isShow;
export const selectOrderID = (state: RootState) => state.cart.order_id;
export const selectDetails = (state: RootState) => state.cart.details;
export const selectAddress = (state: RootState) => state.cart.address;


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default cartSlice.reducer;
