import { createAsyncThunk, createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import ApiService, { AuthService } from "../../services";
import _ from "lodash";
import cookie from "react-cookies";

interface Filter {
  category: number | null;
  onSale: boolean | null;
  bestSeller: boolean | null;
  order: string | null;
  priceS: number | null;
  priceE: number | null;
}

export interface ProductState {
  status: "idle" | "loading" | "failed" | "complete";
  path: string | null;
  filter: Filter;
  curPage: number;
  productlist: [];
  count: number;
}

const initialState: ProductState = {
  status: "idle",
  path: null,
  filter: {
    category: null,
    onSale: null,
    bestSeller: null,
    order: null,
    priceS: null,
    priceE: null,
  },
  curPage: 1,
  productlist: [],
  count: 0,
};

export const ChangeFilterAsync = createAsyncThunk("product/changeFilter", async (params: any) => {
  const { path, filter, page } = params;
  // console.log("params: " , params);
  if (path == "bestseller") {
    filter.bestSeller = true;
  } else if (path == "onsale") {
    filter.onSale = true;
  }

  if (!filter.onSale) {
    filter.onSale = null;
  }
  if (!filter.bestSeller) {
    filter.bestSeller = null;
  }

  if (filter.category === 0) {
    filter.category = null;
  }  

  console.log("filter: " , filter);
  const response = await ApiService.getProductList(filter, page);
  // The value we return becomes the `fulfilled` action payload
  return {path: path, filter: filter, page: page, response: response.data};
});

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    productReload: (state) => {
      state.status = "idle";
    },
    resetFilter: (state) => {
      state.filter = {...initialState.filter};
      state.curPage = 1;
      state.status = "idle";
    },
    resetPath: (state) => {
      state.path = null;
      state.curPage = 1;
      // state.filter = {...initialState.filter};
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ChangeFilterAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(ChangeFilterAsync.fulfilled, (state, action) => {
        console.log("ChangeFilterAsync", action.payload);
        state.path = action.payload.path;
        state.filter = {...current(state).filter, ...action.payload.filter};
        state.productlist = action.payload.response.results;
        state.curPage = action.payload.page;
        state.count = action.payload.response.count;
        state.status = "complete";  
        console.log("ChangeFilterAsync", current(state));      
      })
      .addCase(ChangeFilterAsync.rejected, (state) => {
        state.status = "failed";        
      });
  },
});

export const { productReload, resetFilter, resetPath } = productSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectProductStatus = (state: RootState) => state.product.status;
export const selectPath = (state: RootState) => state.product.path;
export const selectFilter = (state: RootState) => state.product.filter;
export const selectProductCurPage = (state: RootState) => state.product.curPage;
export const selectProductList = (state: RootState) => state.product.productlist;
export const selectProductCount = (state: RootState) => state.product.count;

export default productSlice.reducer;
