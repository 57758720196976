import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// styles
import "../../styles/page.css";

// project
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Item, addToCart, addCartAsync, setDetails } from "./cartSlice";
import { selectUsername } from "../user/userSlice";

// antd
import { Button, Checkbox, Form, Input, Divider } from "antd";

let DetailCmp:any = (props: any, ref:any)=> {
  const dispatch = useAppDispatch();

  const onFinish = (values: any) => {
    console.log("Success:", values);
    dispatch(setDetails(values));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    submitForm: () => {
      form.submit()
    },
    resetForm: () => {
      form.resetFields()
    }
  }));

  return (
    <div className="vlayout " style={{ width:"700px", margin:"30px auto"}}>
      {/* <Divider style={{ borderTopColor: "#999999", marginBottom: "30px" }} /> */}
      <div style={{ fontSize: "28px", marginTop: "50px",  }}>My Details</div>
      <div style={{ margin: "30px auto", width:"100%" }}>
        <Form
          name="detailForm"
          layout="vertical"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 20 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item label="First name" name="firstname" rules={[{ required: true, message: "Please input your first name!" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Last name" name="lastname" rules={[{ required: true, message: "Please input your last name!" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="email" rules={[{ required: true, message: "Please input your email!" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Phone" name="phone" rules={[{ required: true, message: "Please input your phone!" }]}>
            <Input />
          </Form.Item>

          {/* <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item> */}
        </Form>
      </div>
    </div>
  );
}

DetailCmp = forwardRef(DetailCmp);
export default DetailCmp;
