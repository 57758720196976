import React, { useState, useEffect, useRef, forwardRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// styles
import "../styles/page.css";

// project
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { selectUsername } from "../features/user/userSlice";
import { Item, selectItems, selectAmount, selectStatus, showCart, fetchCart, changeQuantityCart, changeCartAsync, deleteCartAsync } from "../features/cart/cartSlice";
import Cart from "../features/cart/Cart";
import DetailCmp from "../features/cart/DetailCmp";
import DeliveryCmp from "../features/cart/DeliveryCmp";
import PaymentCmp from "../features/cart/PaymentCmp";

// library files

// antd
import { LeftOutlined, RightOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { List, Button, message, InputNumber, Divider, Steps } from "antd";
const { Step } = Steps;

export default function CartView(props: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const username = useAppSelector(selectUsername);
  const cartItems = useAppSelector(selectItems);
  const amount = useAppSelector(selectAmount);
  const status = useAppSelector(selectStatus);

  if (status === "idle") {
    if (username === null) {
      dispatch(fetchCart());
    }
  }

  const detailFormRef:any = useRef();
  const deliveryFormRef:any = useRef();

  const next = () => {
    steps[currentIndex].ref?.current.submitForm();
    setCurrentIndex(currentIndex + 1);
  };

  const prev = () => {
    steps[currentIndex].ref?.current.submitForm();
    setCurrentIndex(currentIndex - 1);
  };

  const showCart = () => {
    return (<Cart type="full" />);
  };
  const showDetails = () => {
    return (<DetailCmp ref={detailFormRef} />);
  };
  const showDelivery = () => {
    return (<DeliveryCmp ref={deliveryFormRef} />);
  };
  const showPayment = () => {
    return (<PaymentCmp  />);
  };

  const steps = [
    {
      title: "My Cart",
      content: showCart,
      ref: null,
    },
    {
      title: "My Details",
      content: showDetails,
      ref: detailFormRef,
    },
    {
      title: "My Delivery",
      content: showDelivery,
      ref: deliveryFormRef,
    },
    {
      title: "Payment",
      content: showPayment,
      ref: null,
    },
  ];

  
  const showDiv = steps[currentIndex].content;
  return (    
    <div className="bg vlayout">
      {/*  */}
      <div className="vlayout" style={{ margin: "80px auto 20px auto", width: "80%" }}>
        <Steps current={currentIndex} type="navigation" className="site-navigation-steps">
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{showDiv()}</div>
        {/* buttons */}
        <div className="steps-action">
          {currentIndex < steps.length - 1 && (
            <Button type="primary" size="large" onClick={() => next()}>
              Next step
            </Button>
          )}
          {/* {currentIndex === steps.length - 1 && (
            <Button type="primary" size="large" onClick={() => message.success("Processing complete!")}>
              Finish
            </Button>
          )} */}
          {currentIndex > 0 && (
            <Button style={{ margin: "0 8px" }} size="large"  onClick={() => prev()}>
              Previous step
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
