import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "../config/Config";

class ApiService {
  // category list
  async getCategoryList() {   
    let url = `/api/api_category/`; 
    return await axios.get(url);
  }

  // product list
  async getProductList(params, page = 1) {   
    let url = `/api/api_product/`; 
    params['page'] = page;
    return await axios.get(url, {params: params,});
  }

  async getProducts(path, category, page = 1, bestSeller = false, onSale = false, priceFilter=[1,10000], order = "sold") {
    if (path == "bestseller") {
      bestSeller = true;
    } else if (path == "onsale") {
      onSale = true;
    }
    let url = `/api/api_product/`;
    let params = {
      order: order,
      page: page,
    };
    if (category !== "0") {
      params['category'] = `${category}`;
    }
    if (bestSeller) {
      params['bestSeller'] = true;
    }
    if (onSale) {
      params['onSale'] = true;
    }
    if(priceFilter[0] !==1 || priceFilter[1] !==10000){
      params['priceS'] = priceFilter[0];
      params['priceE'] = priceFilter[1];
    }

    return await axios.get(url, {params: params,});
  }

  // details
  async getProductDetails(productid) {
    let url = `/api/api_details/${productid}`;
    return await axios.get(url);
  }

  // cart
  async getCart() {
    const user = JSON.parse(localStorage.getItem("user"));
    let token = '';
    if (user && user.access) {
      token = "Bearer " + user.access;
      // console.log("token", token);
    }
    return await axios.get(`api/api_cart/`, {headers: {Authorization:token}});
  };
  async addCartItem(data) {
    const user = JSON.parse(localStorage.getItem("user"));
    let token = '';
    if (user && user.access) {
      token = "Bearer " + user.access;
      // console.log("token", token);
    }
    console.log("addCartItem data", data);
    return await axios.post(`api/api_cart2/`, data, {headers: {Authorization:token}});
  };
  async changeCartItem(cartid, data) {
    const user = JSON.parse(localStorage.getItem("user"));
    let token = '';
    if (user && user.access) {
      token = "Bearer " + user.access;
      // console.log("token", token);
    }
    return await axios.patch(`api/api_cart/${cartid}/`, data, {headers: {Authorization:token}});
  };
  async deleteCartItem(cartid) {
    const user = JSON.parse(localStorage.getItem("user"));
    let token = '';
    if (user && user.access) {
      token = "Bearer " + user.access;
      // console.log("token", token);
    }
    return await axios.delete(`api/api_cart/${cartid}/`, {headers: {Authorization:token}});
  };

  // checkout
  async checkout() {    
    return await axios.post(`api/payment/`, {'test': true});
  };

  // customer msg
  addMsg = async (data) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let token = '';
    if (user && user.access) {
      token = "Bearer " + user.access;
      // console.log("token", token);
    }
    console.log("addMsg data", data);
    return await axios.post(`api/api_customermsg/`, data, {headers: {Authorization:token}});
  }

  // subscriber
  addsubscriber = async (data) => {
    return await axios.post(`api/api_subscriber/`, data);
  }

  // payment
  paymentSubmit = async (data) => {
    return await axios.post(`api/payment_2/`, data);
  }

  // order
  // orderitemSubmit = async (data) => {
  //   let datastr = JSON.stringify(data);
  //   return await axios.post(`api/api_orderitem/`, datastr);
  // }
  orderSubmit = async (data) => {
    let datastr = JSON.stringify(data);
    const user = JSON.parse(localStorage.getItem("user"));
    let token = '';
    if (user && user.access) {
      token = "Bearer " + user.access;
    }
    return await axios.post(`api/order/`, datastr, {headers: {Authorization:token}});
  }  


  // tea
  teaMsgSubmit = async (data) => {
    return await axios.post(`api/teaCreateMsg/`, data);
    // return await axios.post(`api/api_masstextconfig/`, data);
  }
  teaMsg = async (data) => {
    return await axios.get(`api/api_masstextconfigall/`, {params: data,});
  }
  teaContacts = async () => {
    let params = {isall:1}
    return await axios.get(`api/api_masscontact/`, {params: params,});
  }
  teaDeleteContact = async (id) => {
    return await axios.delete(`api/api_masscontact/${id}`);
  }
  teaHostList = async () => {
    return await axios.get(`api/api_masscontact_only/`);
  }
}

export default new ApiService();

// TODO： 暂时不添加登录要求，后面再打开

// // Add a request interceptor
// axios.interceptors.request.use((config) => {
//   const user = JSON.parse(localStorage.getItem("user"));

//   if (user && user.access) {
//     const token = "Bearer " + user.access;
//     config.headers.Authorization = token;
//   }

//   return config;
// });

// // 添加响应拦截器--代码实现案例：仅供参考
// axios.interceptors.response.use(
//   function (response) {
//     // 当状态码为2xx/3xx开头的进这里
//     // 对响应数据做点什么
//     return response;
//   },
//   async function (error) {
//     // 响应状态码4xx/5xx进这里
//     // 对响应错误做点什么
//     console.log(`error: ${error.response.status}`);
//     if (error.response.status === 401) {
//       // 身份过期/token无效
//       // //  1.清空vuex的token
//       // store.commit("setToken", "");
//       // store.commit("setRefreshToken", "");
//       // 2. 清空本地token
//       localStorage.removeItem("user");
//       // 3. 跳转到登录页面登录
//       window.location.hash="/login";
//       // router.push({
//       //   path: "/login",
//       // });
//     }
//     return Promise.reject(error);
//   }
// );
