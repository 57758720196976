import React, { useState, useEffect, useRef, forwardRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// styles
import "../styles/page.css";

// project
import ApiService from "../services";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { emtyCart } from "../features/cart/cartSlice";

// library files

// antd
import { Button, Result } from "antd";

export default function PaymentPage(props: any) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  const resultFunc = ()=>{
    console.log("props.type", props.type);
    if(props.type==='true'){
      dispatch(emtyCart());
      return (
        <Result
          status="success"
          title="Thank you!"
          subTitle="Your Payment is Successfully Done."
          extra={[
            <Button type="primary" size="large" onClick={()=>{navigate('/');}}>
              Home
            </Button>,            
          ]}
        />
      );
    }else{
      return (
        <Result
          status="warning"
          title="Warning!"
          subTitle="Payment Failed. Please try again."
          extra={[
            <Button type="primary" size="large" onClick={()=>{navigate('/cartview');}}>
              Try again
            </Button>,            
          ]}
        />
      );
    }
  };
  
  
  return (
    <div className="bg vlayout">
      <div className="vlayout" style={{ margin: "80px auto 20px auto", width: "80%" }}>
      {resultFunc()}
      </div>
    </div>
  );
}
