import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// styles
import "../../styles/page.css";

// project
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Item, addToCart, addCartAsync, showCart } from "./cartSlice";
import { selectUsername } from "../user/userSlice";

// antd

export default function AddToCartButton(props: any) {
  const dispatch = useAppDispatch();
  const username = useAppSelector(selectUsername);

  const itemInfo = props.itemInfo;
  // const showCart = props.showCart;

  const addToCartBtnClick = () => {
    console.log("itemInfo", itemInfo);
    if(username !==null ){
      console.log("username", username);
      dispatch(addCartAsync({ product: itemInfo.product, quantity: itemInfo.quantity, selected: true }));
    }else{
      dispatch(addToCart({ ...itemInfo, timestamp: "testtime", selected: true }));
    }
    
    dispatch(showCart(true));   
    
  };

  return (
    <button className="buyButton" style={{ width: "70%" }} onClick={addToCartBtnClick}>
      Add to Cart
    </button>
  );
}

export function BuyNowButton(props: any) {
  const dispatch = useAppDispatch();
  const username = useAppSelector(selectUsername);

  const navigate = useNavigate();

  const itemInfo = props.itemInfo;
  // const showCart = props.showCart;

  const buynowBtnClick = () => {
    console.log("itemInfo", itemInfo);
    if(username !==null ){
      console.log("username", username);
      dispatch(addCartAsync({ product: itemInfo.product, quantity: itemInfo.quantity, selected: true }));
    }else{
      dispatch(addToCart({ ...itemInfo, timestamp: "testtime", selected: true }));
    }
    
    // dispatch(showCart(true));
    navigate("/cartview");
  };

  return (
    <button className="buyButton_2" style={{ width: "70%" }} onClick={buynowBtnClick}>
      Buy Now
    </button>
  );
}
