import { useLocation, useNavigate, useParams } from "react-router-dom";


export default function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

let path = {
  'home':{'path':'/', 'name':'Home'}, // 主页

  'product':{'path':'/product', 'name':'Product'}, 
  'product_All':{'path':'/product/0', 'name':'All'}, 
  'product_Games':{'path':'/product/2', 'name':'Games'}, 
  'product_Consoles':{'path':'/product/1', 'name':'Consoles'}, 
  'product_Controllers':{'path':'/product/3', 'name':'Controllers'}, 
  'product_Accessories':{'path':'/product/4', 'name':'Accessories'}, 
  'product_BuildingBlocks':{'path':'/product/5', 'name':'Building Blocks'}, 

  'product_detail':{'path':'/productdetail', 'name':'ProductDetail'}, 

  'bestseller_All':{'path':'/bestseller/0', 'name':'Best Sellers'}, 
  
  'onsale_All':{'path':'/onsale/0', 'name':'On Sale'}, 
  'contactUs': {'path':'/contactUs', 'name':'Contact Us'},
  'login': {'path':'/login', 'name':'Login'}, 
  // 'cart': {'path':'/cart', 'name':'Cart'},
  'cart': {'path':'/cartview', 'name':'Cart'},

};



function getRoutePath(key) {
  return path[key]['path'];
}

function getRouteName(key) {
  return path[key]['name'];
}

export {getRoutePath, getRouteName};
