import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// library
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";

// styles
import "../../styles/page.css";

// project
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { setDetails, setAddr } from "./cartSlice";
import { selectUsername } from "../user/userSlice";

// antd
import { Button, Checkbox, Form, Input, Divider } from "antd";

let DeliveryCmp: any = (props: any, refForm: any) => {
  const dispatch = useAppDispatch();

  let address = "";
  let addressObject = "";

  const onFinish = (values: any) => {
    console.log("Success:", values);
    console.log("address:", address);
    console.log("addressObject:", addressObject);
    dispatch(setAddr({addrStr:address, addrJson:addressObject}));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [form] = Form.useForm();
  useImperativeHandle(refForm, () => ({
    submitForm: () => {
      form.submit();
    },
    resetForm: () => {
      form.resetFields();
    },
  }));

  
  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: process.env.REACT_APP_GOOGLE_APIKEY,
    onPlaceSelected: (place) => {
      if (place?.formatted_address) {
        address = place.formatted_address;
        addressObject = JSON.stringify(place.address_components);
        console.log(place);
        console.log(address);
        console.log(addressObject);
      }
    },
    language: "en",
    options: {
      types: ["address"],
      componentRestrictions: { country: "au" },
    },
  });

  return (
    <div className="vlayout " style={{ width: "700px", margin: "30px auto" }}>
      <div style={{ fontSize: "28px", marginTop: "50px" }}>My Delivery</div>
      <div style={{ margin: "30px auto", width: "100%" }}>
        <Form
          name="deliveryForm"
          layout="vertical"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 20 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item label="Address" name="address" rules={[{ required: true, message: "Please input your address!" }]}>
            <input ref={ref} onKeyDown={(e)=>{if(e.key==='Enter')return false;}}/>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

DeliveryCmp = forwardRef(DeliveryCmp);
export default DeliveryCmp;
