import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// styles
import "../styles/imagecarousel.css";
import "../styles/page.css";

// project files
import ApiService from "../services";
import AddToCartButton from "../features/cart/AddToCartButton";

// library files
import ItemsCarousel from "react-items-carousel";

// antd
import { Button, Row, Col } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

export default function ImageCarousel(props: any) {
  const [activeItem, setActiveItem] = useState(0);
  // 根据category价值图片信息
  const category = props.category;
  const showNumber = props.showNumber;
  const [load, setLoad] = useState(false);
  const [productList, setProductList] = useState([]);

  console.log("ImageCarousel", category);

  if (!load) {
    if (category === "best_sellers") {
      ApiService.getProducts("bestseller", "0", 1)
        .then((response) => {
          setLoad(true);
          setProductList(response.data.results);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (category === "Accessories") {
      ApiService.getProducts("product", "4")
        .then((response) => {
          setLoad(true);
          setProductList(response.data.results);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (category === "Games") {
      ApiService.getProducts("product", "2")
        .then((response) => {
          setLoad(true);
          setProductList(response.data.results);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  useEffect(() => {});

  const onChangeClickHandler = (e: any) => {
    console.log(e);
    setActiveItem(e);
  };

  console.log("productList", productList);

  const generateProductCard = () => {
    return productList.map((value: any, index: number) => {
      return <ImageCard key={value.id} productid={value.id} cover={value.cover} title={value.name} price={value.price} realprice={value.realprice} quantity={value.quantity}></ImageCard>;
    });
  };

  return (
    <ItemsCarousel
      requestToChangeActive={onChangeClickHandler}
      activeItemIndex={activeItem}
      numberOfCards={showNumber}
      gutter={40}
      leftChevron={
        <Button className="chevronButton">
          <LeftOutlined className="chevronIcon" />
        </Button>
      }
      rightChevron={
        <Button className="chevronButton">
          <RightOutlined className="chevronIcon" />
        </Button>
      }
      chevronWidth={60}
      outsideChevron={true}
    >
      {generateProductCard()}
    </ItemsCarousel>
  );
}

export function ImageCard(props: any) {
  // const index = props.index;
  // const imgSrc = `../datas/imgs/${index}.webp`;
  const price = () => {
    if (props.price === props.realprice) {
      return <p className="cardDescription">${props.realprice}</p>;
    } else {
      return (
        <p className="cardDescription ">
          <span className="deleteText">${props.price}</span> ${props.realprice}
        </p>
      );
    }
  };
  return (
    <div className="vlayout">
      <Link to={`/productdetail/${props.productid}`}>
        <div className="img_div">
          <div className="mask"></div>
          <img src={props.cover} />
          {/* <img src={require(`../datas/imgs/${index}.webp`)} /> */}
        </div>
      </Link>
      <Link to={`/productdetail/s`}>
        <p className="cardTitle">{props.title}</p>
      </Link>
      {/* <p className="cardDescription">{props.price}</p> */}
      {price()}
      {/* <button className="buyButton">Add to Cart</button> */}
      <AddToCartButton        
        itemInfo={{ product: props.productid, name: props.title, price: props.price, realprice: props.realprice, cover: props.cover, quantity: 1, quantityMax: props.quantity }}
      />
    </div>
  );
}
