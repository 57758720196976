import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// styles
import "../styles/page.css";

// project
import ApiService from "../services";
import { ImageCarousel, ImageCard, CategoryCard } from "../components";
import Cart from "../features/cart/Cart";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import {
  selectProductStatus,
  selectPath,
  selectFilter,
  selectProductCurPage,
  selectProductList,
  selectProductCount,
  productReload,
  resetFilter,
  ChangeFilterAsync,
} from "../features/product/productSlice";
import { selectCategoryList } from "../features/category/categorySlice";

// library files
import { useForm } from "react-hook-form";

// antd
import { PlusOutlined, MinusOutlined, RightOutlined } from "@ant-design/icons";
import { Collapse, Slider, Select, List, Pagination, Checkbox, Drawer } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
const { Panel } = Collapse;
const { Option } = Select;

export default function Product(props: any) {
  const dispatch = useAppDispatch();
  const categoryList = useAppSelector(selectCategoryList);

  const productStatus = useAppSelector(selectProductStatus);
  const productPath = useAppSelector(selectPath);
  const productFilter = useAppSelector(selectFilter);
  const curPage = useAppSelector(selectProductCurPage);
  const productList = useAppSelector(selectProductList);
  const productCount = useAppSelector(selectProductCount);

  let path = props.path;
  let categoryID: number | null = parseInt(props.type);
  let title = "";

  if (productStatus === "idle") {
    dispatch(ChangeFilterAsync({ path: path, filter: { ...productFilter, category: categoryID }, page: 1 }));
  } else if (productStatus === "complete") {
    if (props.path !== productPath) {
      // console.log("resetFilter：", props.path, productPath );
      dispatch(resetFilter());
    } else {
      let tmpCategory = categoryID === 0 ? null : categoryID;
      if (tmpCategory !== productFilter.category) {
        dispatch(productReload());
      }
    }
  }

  if (path == "product") {
    title = "";
  } else if (path == "bestseller") {
    title = "Best Sellers - ";
  } else if (path == "onsale") {
    title = "On Sale - ";
  }

  // console.log("Product productFilter：", productFilter);

  try {
    categoryList.forEach((category) => {
      if (category.id === categoryID) {
        title += category.name;
        throw new Error("EndIterative");
      }
    });
  } catch (e: any) {
    if (e.message != "EndIterative") throw e;
  }

  // 返回顶部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChangePage = (page: any, pageSize: any) => {
    dispatch(ChangeFilterAsync({ path: path, filter: { ...productFilter, category: categoryID }, page: page }));
    // ApiService.getProducts(filter.path, filter.category, page)
    //   .then((response) => {
    //     setLoad(true);
    //     setResult({ total: response.data.count, productList: response.data.results });
    //     console.log(response.data);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  };

  const generateFilterCollectionPart = () => {
    let liList: any = [];
    categoryList.forEach((item, index) => {
      if (categoryID === item.id) {
        liList.push(
          <li style={{ whiteSpace: "nowrap" }} key={index}>
            <Link to={`/${path}/${item.id}`} style={{ color: "#8858ed", fontWeight: "bold" }}>
              {item.name}
            </Link>
          </li>
        );
      } else {
        liList.push(
          <li style={{ whiteSpace: "nowrap" }} key={index}>
            <Link to={`/${path}/${item.id}`}>{item.name}</Link>
          </li>
        );
      }
    });
    return <ul className="filterlist">{liList}</ul>;
  };

  const generateFilterCheckPart = () => {
    // console.log("productFilter", productFilter);
    return (
      <div>
        <div>
          {path !== "bestseller" && (
            <Checkbox
              onChange={(e) => {
                dispatch(ChangeFilterAsync({ path: props.path, filter: { ...productFilter, bestSeller: e.target.checked }, page: 1 }));
              }}
              checked={productFilter.bestSeller === null ? false : productFilter.bestSeller}
              defaultChecked={productFilter.bestSeller === null ? false : productFilter.bestSeller}
            >
              <span style={{ whiteSpace: "nowrap" }}>Best Sellers</span>
            </Checkbox>
          )}
        </div>
        <div>
          {path !== "onsale" && (
            <Checkbox
              onChange={(e) => {
                dispatch(ChangeFilterAsync({ path: props.path, filter: { ...productFilter, onSale: e.target.checked }, page: 1 }));
              }}
              checked={productFilter.onSale === null ? false : productFilter.onSale}
              defaultChecked={productFilter.onSale === null ? false : productFilter.onSale}
            >
              <span style={{ whiteSpace: "nowrap" }}>On Sale</span>
            </Checkbox>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="vlayout">
      <div className="bg">
        <div className="content vlayout">
          <div className="productTitle">{title}</div>
          <div className="hlayout">
            <div className="filterPart vlayout">
              <div className="filterTitle filterItem">Filter by</div>
              {/* Collection filter */}
              <div className="filterItem">
                <Collapse
                  defaultActiveKey={["1"]}
                  ghost
                  expandIconPosition="end"
                  expandIcon={({ isActive }) => (isActive ? <MinusOutlined style={{ color: "white", fontSize: "18px" }} /> : <PlusOutlined style={{ color: "white", fontSize: "18px" }} />)}
                >
                  <Panel header={<span className="filterlistTitle">Collection</span>} key="1">
                    {generateFilterCollectionPart()}
                    {generateFilterCheckPart()}
                  </Panel>
                </Collapse>
              </div>
              {/* price filter */}
              <div className="filterItem">
                <Collapse
                  defaultActiveKey={["0"]}
                  ghost
                  expandIconPosition="end"
                  expandIcon={({ isActive }) => (isActive ? <MinusOutlined style={{ color: "white", fontSize: "18px" }} /> : <PlusOutlined style={{ color: "white", fontSize: "18px" }} />)}
                >
                  <Panel header={<span className="filterlistTitle">Price</span>} key="1">
                    <div className="vlayout">
                      {productFilter.priceE || productFilter.priceS || (
                        <Slider
                          min={0}
                          max={2000}
                          range={{
                            draggableTrack: true,
                          }}
                          defaultValue={[0, 2000]}
                          //
                          onAfterChange={(v: any) => {
                            dispatch(ChangeFilterAsync({ path: props.path, filter: { ...productFilter, priceS: v[0], priceE: v[1] }, page: 1 }));
                          }}
                        />
                      )}
                      <div className="hlayout">
                        <span style={{ color: "white", whiteSpace: "nowrap" }}>{productFilter.priceS}</span>
                        <span style={{ color: "white", whiteSpace: "nowrap" }}>{productFilter.priceE}</span>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            </div>
            <div className="listPart">
              <div className="vlayout_end">
                {/* sort */}
                <div className="sortDiv">
                  <Select
                    defaultValue="-created_time"
                    style={{ width: 220 }}
                    onChange={(v) => {
                      console.log("Select", v);
                      if (v !== "-1") {
                        dispatch(ChangeFilterAsync({ path: props.path, filter: { ...productFilter, order: v }, page: 1 }));
                      }
                    }}
                  >
                    <Option value="-1" disabled={true}>
                      Sort by
                    </Option>
                    <Option value="-created_time">Newest</Option>
                    <Option value="price">Price (low to high)</Option>
                    <Option value="-price">Price (high to low)</Option>
                    <Option value="name">Name A-Z</Option>
                    <Option value="-name">Name Z-A</Option>
                  </Select>
                </div>
                <div style={{ width: "100%" }}>
                  <List
                    grid={{
                      gutter: 32,
                      column: 3,
                    }}
                    dataSource={productList}
                    renderItem={(item: any) => (
                      <List.Item>
                        <ImageCard key={item.id} productid={item.id} cover={item.cover} title={item.name} price={item.price} realprice={item.realprice} quantity={item.quantity}></ImageCard>
                      </List.Item>
                    )}
                  />
                </div>
                <div className="paginationDiv">
                  <Pagination defaultCurrent={1} current={curPage} total={productCount} pageSize={6} onChange={onChangePage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
