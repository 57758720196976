import { createAsyncThunk, createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import ApiService, { AuthService } from "../../services";
import _ from "lodash";
import cookie from "react-cookies";

interface Category {
  id: number;
  name: string;
}

export interface CategoryState {
  status: "idle" | "loading" | "failed" | "complete";
  categorylist: Array<Category>;
}

const initialState: CategoryState = {
  status: "idle",
  categorylist: [{ id: 0, name: "All" }],
};

export const getCategoryListAsync = createAsyncThunk("category/getCategoryListAsync", async () => {
  const response = await ApiService.getCategoryList();
  return response.data;
});

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {    
  },
  extraReducers: (builder) => {
    builder
      //getCategoryListAsync
      .addCase(getCategoryListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCategoryListAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getCategoryListAsync.fulfilled, (state, action) => {
        // console.log("getCategoryList", action.payload);
        state.categorylist = initialState.categorylist.concat(action.payload);
        // console.log("getCategoryList", current(state).categorylist);
        state.status = "complete";
      });
  },
});

// export const { getCategoryList } = categorySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCategoryStatus = (state: RootState) => state.category.status;
export const selectCategoryList = (state: RootState) => state.category.categorylist;

export default categorySlice.reducer;
