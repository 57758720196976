import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// styles
import "../styles/page.css";

// project
import withRouter, { getRoutePath, getRouteName } from "../utils/withRouter";
import ApiService from "../services/ApiService";
import { ImageCarousel, CategoryCard } from "../components";

// library files
import { useForm } from "react-hook-form";

// antd
import { RightOutlined } from "@ant-design/icons";
import { message } from "antd";

export default function Home(props: any) {
  const navigate = useNavigate();
  const {
    register,
    getFieldState,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm({ mode: "onSubmit" });

  const onSubmit = (data: any) => {
    console.log(JSON.stringify(data));
    ApiService.addsubscriber(data).then((response) => {
      console.log(response.data);
      message.success('Thanks for submitting!');
      reset();
    })
    .catch(function (error) {
      console.log(error);
      message.error(error);
    });
  };
  const onChangeForm = (data: any) => {
    // clearErrors('email');
    // console.log("onChangeForm", getFieldState("email").error?.type);
  };

  console.log("Home");

  return (
    <div className="vlayout">
      {/* part1 */}
      <div className="bg part1">
        <div className="vlayout">
          <div className="part1_main">
            <div className="content hlayout">
              {/* col1 */}
              <div className="part1_main_col1">
                <div className="vlayout">
                  <span className="part1_main_col1_item1">Power up your LIFE</span>
                  <span className="part1_main_col1_item2">CYBERPUNK 2077</span>
                  <span className="part1_main_col1_item3">Now Available on PC & Console</span>
                  <button className="normalButton" onClick={() => navigate(getRoutePath("product_All"))}>
                    <span>Buy Now</span>
                  </button>
                </div>
              </div>
              {/* col2 */}
              <div className="part1_main_col2">
                <img className="cover_img" src="/imgs/Cyber_copy.webp"></img>
              </div>
            </div>
          </div>
          <div className="part1_bottom"></div>
        </div>
      </div>
      {/* part2 BEST SELLERS */}
      <div className="bg">
        <div className="vlayout bgBlack">
          {/* title */}
          <div className="hlayout_center content title">
            <span className="categoryTitle">BEST SELLERS</span>
            <button className="normalButton" onClick={() => navigate(getRoutePath("bestseller_All"))}>
              View All
            </button>
          </div>
          {/* list */}
          <div className="content produclist">
            <ImageCarousel category="best_sellers" showNumber={4} />
          </div>
        </div>
      </div>
      {/* part3 */}
      <div className="bg">
        <div className="vlayout bgGray">
          {/* title */}
          <div className="hlayout_center content title">
            <span className="categoryTitle">SHOP BY CATEGORY</span>
          </div>
          {/* list */}
          <div className="content produclist hlayout">
            <CategoryCard category="Consoles" />
            <CategoryCard category="Accessories" />
            <CategoryCard category="Controllers" />
          </div>
        </div>
      </div>
      {/* part4 */}
      <div className="bg">
        <div className="content produclist hlayout">
          {/* col1 */}
          <div className="part4_col1">
            <div className="vlayout_center">
              <span className="part4_col1_item1">THIS WEEK'S DEALS</span>
              <span className="part4_col1_item2">10%</span>
              <span className="part4_col1_item3">off all games</span>
              <button className="normalButton" onClick={() => navigate(getRoutePath("product_Games"))} style={{width:"300px"}}>
                <span>Shop Now</span>
              </button>
            </div>
          </div>
          {/* col2 */}
          <div className="part4_col2">
            <img className="cover_img" src="/imgs/Kira.webp"></img>
          </div>
          {/* col3 */}
          <div className="part4_col3">
            <img className="cover_img" src="/imgs/DeadatLast.webp"></img>
          </div>
          {/* col4 */}
          <div className="part4_col4">
            <img className="cover_img" src="/imgs/Chronosplit.webp"></img>
          </div>
        </div>
      </div>
      {/* part5 */}
      <div className="bg">
        <div className="vlayout bgBlack">
          {/* title */}
          <div className="hlayout_center content title">
            <span className="categoryTitle">UPGRADE YOUR GEAR</span>
            <button className="normalButton" onClick={() => navigate(getRoutePath("product_Accessories"))}>
              View All
            </button>
          </div>
          {/* list */}
          <div className="content produclist">
            <ImageCarousel category="Accessories" showNumber={4} />
          </div>
        </div>
      </div>
      {/* part6 */}
      <div className="bg">
        <div className="bgImage1">
          <div className="content vlayout_center">
            <span className="part6_item1">SPEND & SAVE</span>
            <span className="part6_item2">Save 20% when you spend more than $125</span>
            <button className="buyButton" style={{ marginBottom: "70px" }} onClick={() => navigate(getRoutePath("onsale_All"))}>
              Shop Now
            </button>
          </div>
        </div>
      </div>
      {/* part7 TRENDING GAMES */}
      {/* <div className="bg">
        <div className="vlayout bgBlack">
          <div className="hlayout_center content title">
            <span className="categoryTitle">TRENDING GAMES</span>
            <button className="normalButton" onClick={() => navigate(getRoutePath("product_Games"))}>
              View All
            </button>
          </div>
          <div className="content produclist">
            <ImageCarousel category="Games" showNumber={4} />
          </div>
        </div>
      </div> */}
      {/* part8 */}
      <div className="bg">
        <div className="bgImage2">
          <div className="content vlayout_center">
            <span className="categoryTitle" style={{ marginTop: "50px" }}>
              NEWSLETTER
            </span>
            <span className="formlabel">Sign up to receive updates on new products and special offers</span>
            <form className="vlayout_center emailForm" onSubmit={handleSubmit(onSubmit)} onChange={onChangeForm}>
              <label className="formlabel" htmlFor="email" style={{ marginBottom: "8px" }}>
                Email *
              </label>
              <input
                className="emailInput"
                type="email"
                placeholder="Enter your email"
                {...register("email", { required: true, pattern: /^.+@.+\.[a-zA-Z]{2,63}$/ })} //
                aria-invalid="false"
                aria-required="true"
                maxLength={250}
                minLength={1}
              />
              <input type="submit" value="Submit" className="submitButton" style={{ marginBottom: "10px" }} />
              {/* Submit
              </button> */}
              {errors.email && getFieldState("email").error?.type === "required" && <p style={{ color: "red" }}>Please fill out your email address.</p>}
              {errors.email && getFieldState("email").error?.type === "pattern" && <p style={{ color: "red" }}>Please enter a valid email address.</p>}
            </form>
          </div>
        </div>
      </div>
      {/* part9 */}
      <div className="bg">
        <div className="bgImage3">
          <div className="content vlayout_center">
            <img src="/imgs/GamesStackCopy.webp" style={{ objectFit: "cover", marginTop: "-115px" }}></img>
          </div>
        </div>
      </div>
    </div>
  );
}
