import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// styles
import "../styles/imagecarousel.css";
import "../styles/page.css";

// project
import withRouter, { getRoutePath, getRouteName } from "../utils/withRouter";

// antd
import { Button, Row, Col } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

export default function CategoryCard(props: any) {
  const navigate = useNavigate();
  const category = props.category;
  // onClick={()=>navigate(getRoutePath('product_Consoles'))}
  const keyString = `product_${category}`
  return (
    <div className="vlayout category-card">
      <div className="category_img_div" onClick={()=>navigate(getRoutePath(keyString))}>
        <div className="category_mask"></div>
        <img className="category_img" src={`/imgs/${category}.webp`} />
      </div>
      <a className="category"><p className="category" onClick={()=>navigate(getRoutePath(keyString))}>{category}</p></a>
    </div>
  );
}