import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// styles
import "../../styles/page.css";

// library files
// import { useForm } from "react-hook-form";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";

// project
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectUsername } from "../user/userSlice";
import { Item, selectItems, selectAmount, selectStatus, showCart, fetchCart, changeQuantityCart, changeCartAsync, deleteCartAsync, fetchCartAsync } from "./cartSlice";

// antd
import { CloseOutlined, LockOutlined } from "@ant-design/icons";
import { message, List, Image, InputNumber, Divider, Button, Modal, Form, Input, InputRef } from "antd";
import ApiService from "../../services";

export default function Cart(props: any) {
  const type = props.type;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const username = useAppSelector(selectUsername);
  const cartItems = useAppSelector(selectItems);
  const amount = useAppSelector(selectAmount);
  const status = useAppSelector(selectStatus);

  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // const {
  //   register,
  //   getFieldState,
  //   handleSubmit,
  //   formState: { errors },
  //   clearErrors,
  //   reset,
  // } = useForm({ mode: "onSubmit" });

  const onSubmit = (data: any) => {
    console.log("onSubmit", JSON.stringify(data));
    // ApiService.addMsg(data).then((response) => {
    //   // setResult({ total: response.data.count, productList: response.data.results });
    //   console.log(response.data);
    //   message.success('Thanks for submitting!');
    //   // alert('submit success');
    //   reset();
    // })
    // .catch(function (error) {
    //   console.log(error);
    //   message.error(error);
    // });
  };

  if (status === "idle") {
    if (username === null) {
      dispatch(fetchCart());
    } else {
      dispatch(fetchCartAsync());
    }
  }

  console.log(cartItems);

  const price = (item: any) => {
    if (item.price === item.realprice) {
      return (
        <p className="cardDescription" style={{ fontWeight: "bold", marginBottom: "5px" }}>
          ${item.realprice}
        </p>
      );
    } else {
      return (
        <p className="cardDescription" style={{ fontWeight: "bold", marginBottom: "5px" }}>
          <span className="deleteText">${item.price}</span> ${item.realprice}
        </p>
      );
    }
  };

  const generateCartDrawer = () => {
    return (
      <div>
        <div style={{ padding: "20px" }}>
          <List
            itemLayout="horizontal"
            dataSource={cartItems}
            renderItem={(item) => (
              <List.Item>
                <div className="hlayout" style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <div style={{ marginRight: "20px" }}>
                    <Image width={80} height={80} src={item.cover} style={{ objectFit: "cover" }} />
                  </div>
                  <div className="vlayout_start">
                    <div style={{ fontSize: "16px" }}>{item.name}</div>
                    <div style={{ fontSize: "16px" }}>{price(item)}</div>
                    <div style={{ fontSize: "14px", marginTop: "0px" }}>
                      <InputNumber
                        size="middle"
                        min={0}
                        max={item.quantityMax}
                        value={item.quantity}
                        onChange={(value: any) => {
                          console.log("value================", value);
                          // if  (value > item.quantityMax) {
                          //   value = item.quantityMax;
                          //   message.warning(`Only ${item.quantityMax} available`);
                          // }
                          if (username !== null) {
                            if (value === 0) {
                              dispatch(deleteCartAsync(item.id));
                            } else {
                              dispatch(changeCartAsync({ cartid: item.id, data: { quantity: value } }));
                            }
                          } else {
                            dispatch(changeQuantityCart({ ...item, quantity: value }));
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </List.Item>
            )}
          />
          <div style={{ fontSize: "26px", fontWeight: "bold", marginTop: "20px" }}>Subtotal</div>
          <div style={{ fontSize: "26px", fontWeight: "bold" }}>${amount}</div>
        </div>
        <Divider style={{ borderTopColor: "white" }} />
        <div style={{ width: "80%", margin: "30px auto 30px auto" }}>
          <button
            className="formSubmitButton"
            onClick={() => {
              navigate("/cartview");
              dispatch(showCart(false));
            }}
          >
            View Cart
          </button>
        </div>
      </div>
    );
  };

  const generateCartFull = () => {
    return (
      <div className="hlayout content" style={{ margin: "80px auto 20px auto", width:"90%" }}>
        {/* cart */}
        <div className="vlayout" style={{ marginRight: "50px", width: "75%" }}>
          <div style={{ fontSize: "20px" }}>Items in your bag</div>
          <Divider style={{ borderTopColor: "#999999" }} />
          <div>
            <div>
              <List
                split={false}
                itemLayout="horizontal"
                dataSource={cartItems}
                renderItem={(item) => (
                  <List.Item>
                    <div className="vlayout">
                      <div className="hlayout" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <div style={{ marginRight: "20px" }}>
                          <Image width={80} height={80} src={item.cover} style={{ objectFit: "cover" }} />
                        </div>
                        <div className="vlayout_start">
                          <div style={{ fontSize: "16px" }}>{item.name}</div>
                          <div style={{ fontSize: "14px", marginTop: "10px" }}>{price(item)}</div>
                        </div>
                        <div style={{ fontSize: "14px", marginTop: "0px" }}>
                          <InputNumber
                            size="middle"
                            min={0}
                            max={item.quantityMax}
                            value={item.quantity}
                            onChange={(value: any) => {
                              console.log("value================", value);
                              if (username !== null) {
                                if (value === 0) {
                                  dispatch(deleteCartAsync(item.id));
                                } else {
                                  dispatch(changeCartAsync({ cartid: item.id, data: { quantity: value } }));
                                }
                              } else {
                                dispatch(changeQuantityCart({ ...item, quantity: value }));
                              }
                            }}
                          />
                        </div>
                        <div style={{ fontSize: "16px", padding: "0px 30px", width: "200px" }}>${parseFloat((item.realprice * item.quantity).toFixed(2))}</div>
                        <div style={{ fontSize: "16px", padding: "0px 30px" }}>
                          <Button
                            shape="circle"
                            onClick={() => {
                              if (username !== null) {
                                dispatch(deleteCartAsync(item.id));
                              } else {
                                dispatch(changeQuantityCart({ ...item, quantity: 0 }));
                              }
                            }}
                            icon={<CloseOutlined />}
                          ></Button>
                        </div>
                      </div>
                      <Divider style={{ borderTopColor: "#999999" }} />
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </div>
        </div>
        {/* total */}
        <div className="vlayout" style={{ width: "25%" }}>
          <div style={{ fontSize: "20px" }}>Order summary</div>
          <Divider style={{ borderTopColor: "#999999" }} />
          <div className="hlayout">
            <div style={{ fontSize: "16px" }}>Subtotal</div>
            <div style={{ fontSize: "16px" }}>${amount}</div>
          </div>
          <div className="hlayout" style={{ marginTop: "16px" }}>
            <div style={{ fontSize: "16px" }}>Shipping</div>
            <div style={{ fontSize: "16px" }}>FREE</div>
          </div>
          <Divider style={{ borderTopColor: "#999999" }} />
          <div className="hlayout" style={{ marginTop: "16px" }}>
            <div style={{ fontSize: "20px" }}>Total</div>
            <div style={{ fontSize: "20px" }}>${amount}</div>
          </div>
          <div style={{ display: "none" }}>
            <input type="number" id="amount" name="amount" value={amount} placeholder="" />
          </div>
          <div style={{ display: "none" }}>
            <input type="text" id="email" name="email" value="test@gmail.com" placeholder="" />
          </div>
          {/* <button type="submit" className="buyButton" style={{ width: "100%", marginTop: "20px", height: "42px" }}>
              Checkout
            </button>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              {" "}
              <LockOutlined /> Secure Checkout
            </div> */}
        </div>
      </div>
    );
  };

  const generateCartFull2 = () => {
    return (
      <div className="hlayout content">
        {/* cart */}
        <div className="vlayout" style={{ marginRight: "50px", width: "75%" }}>
          <div style={{ fontSize: "20px" }}>My cart</div>
          <Divider style={{ borderTopColor: "#999999" }} />
          <div>
            <div>
              <List
                split={false}
                itemLayout="horizontal"
                dataSource={cartItems}
                renderItem={(item) => (
                  <List.Item>
                    <div className="vlayout">
                      <div className="hlayout" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <div style={{ marginRight: "20px" }}>
                          <Image width={80} height={80} src={item.cover} style={{ objectFit: "cover" }} />
                        </div>
                        <div className="vlayout_start">
                          <div style={{ fontSize: "16px" }}>{item.name}</div>
                          <div style={{ fontSize: "14px", marginTop: "10px" }}>{price(item)}</div>
                        </div>
                        <div style={{ fontSize: "14px", marginTop: "0px" }}>
                          <InputNumber
                            size="middle"
                            min={0}
                            max={item.quantityMax}
                            value={item.quantity}
                            onChange={(value: any) => {
                              console.log("value================", value);
                              if (username !== null) {
                                if (value === 0) {
                                  dispatch(deleteCartAsync(item.id));
                                } else {
                                  dispatch(changeCartAsync({ cartid: item.id, data: { quantity: value } }));
                                }
                              } else {
                                dispatch(changeQuantityCart({ ...item, quantity: value }));
                              }
                            }}
                          />
                        </div>
                        <div style={{ fontSize: "16px", padding: "0px 30px", width: "200px" }}>${parseFloat((item.realprice * item.quantity).toFixed(2))}</div>
                        <div style={{ fontSize: "16px", padding: "0px 30px" }}>
                          <Button
                            shape="circle"
                            onClick={() => {
                              if (username !== null) {
                                dispatch(deleteCartAsync(item.id));
                              } else {
                                dispatch(changeQuantityCart({ ...item, quantity: 0 }));
                              }
                            }}
                            icon={<CloseOutlined />}
                          ></Button>
                        </div>
                      </div>
                      <Divider style={{ borderTopColor: "#999999" }} />
                    </div>
                  </List.Item>
                )}
              />
            </div>
            {/* <Divider style={{ borderTopColor: "#999999" }} /> */}
          </div>
          {/* address */}
          <div style={{ fontSize: "20px", marginTop: "30px" }}>My Address</div>
          <Divider style={{ borderTopColor: "#999999" }} />

          <div style={{ paddingLeft: "20px" }}>
            <Button type="primary" size="large" onClick={showModal}>
              Add Address
            </Button>
          </div>
          <Modal title="Address" footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <form className="vlayout_center contactForm" name="AddressForm" onSubmit={onSubmit}>
              <div className="vlayout">
                {/* first row */}
                <div className="hlayout" style={{ marginBottom: "15px" }}>
                  <div className="vlayout">
                    <label className="formlabel" htmlFor="firstname" style={{ marginBottom: "8px" }}>
                      First Name *
                    </label>
                    <input
                      className="forminput leftinput"
                      type="text"
                      placeholder=""
                      // {...register("firstname", { required: true })}
                      aria-invalid="false"
                      aria-required="true"
                      maxLength={50}
                      minLength={1}
                    />
                  </div>
                  <div className="vlayout">
                    <label className="formlabel" htmlFor="lastname" style={{ marginBottom: "8px" }}>
                      Last Name *
                    </label>
                    <input
                      className="forminput rightinput"
                      type="text"
                      placeholder=""
                      // {...register("lastname", { required: true })} //
                      aria-invalid="false"
                      aria-required="true"
                      maxLength={50}
                      minLength={1}
                    />
                  </div>
                </div>
                {/* second row */}
                <div className="hlayout" style={{ marginBottom: "15px" }}>
                  <div className="vlayout">
                    <label className="formlabel" htmlFor="email" style={{ marginBottom: "8px" }}>
                      Email *
                    </label>
                    <input
                      className="forminput"
                      type="email"
                      placeholder=""
                      // {...register("email", { required: true, pattern: /^.+@.+\.[a-zA-Z]{2,63}$/ })}
                      aria-invalid="false"
                      aria-required="true"
                      maxLength={250}
                      minLength={1}
                    />
                  </div>
                  <div className="vlayout">
                    <label className="formlabel" htmlFor="phone" style={{ marginBottom: "8px" }}>
                      Phone
                    </label>
                    <input
                      className="forminput"
                      type="text"
                      placeholder=""
                      // {...register("phone", { required: false, pattern: /[0-9]{9,10}$/ })} //
                      aria-invalid="false"
                      aria-required="false"
                      maxLength={10}
                      minLength={9}
                    />
                  </div>
                </div>
                {/* third row */}
                <div className="hlayout" style={{ marginBottom: "0px" }}>
                  <div className="vlayout">
                    <label className="formlabel" htmlFor="addr" style={{ marginBottom: "8px" }}>
                      Address
                    </label>
                    <Autocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_APIKEY}
                      onPlaceSelected={(place) => {
                        console.log(place.formatted_address);
                      }}
                      language="en"
                      options={{
                        types: ["address"],
                        componentRestrictions: { country: "au" },
                      }}
                      defaultValue="23 parkland"
                      // {...register("addr", )}
                      aria-invalid="false"
                      aria-required="true"
                    />
                  </div>
                </div>
                <input type="submit" value="Submit" className="contactSubmit bottominput" style={{ marginBottom: "10px" }} />
                {/* Submit */}
              </div>
            </form>
          </Modal>
        </div>
        {/* order */}
        <div className="vlayout" style={{ width: "25%" }}>
          {/* <form action={`https://cyberpunk2077.store/api/payment_1/`} method="POST"> */}
          <form action={`${process.env.REACT_APP_SERVER}/api/payment_1/`} method="POST">
            <div style={{ fontSize: "20px" }}>Order summary</div>
            <Divider style={{ borderTopColor: "#999999" }} />
            <div className="hlayout">
              <div style={{ fontSize: "16px" }}>Subtotal</div>
              <div style={{ fontSize: "16px" }}>${amount}</div>
            </div>
            <div className="hlayout" style={{ marginTop: "16px" }}>
              <div style={{ fontSize: "16px" }}>Shipping</div>
              <div style={{ fontSize: "16px" }}>FREE</div>
            </div>
            <Divider style={{ borderTopColor: "#999999" }} />
            <div className="hlayout" style={{ marginTop: "16px" }}>
              <div style={{ fontSize: "20px" }}>Total</div>
              <div style={{ fontSize: "20px" }}>${amount}</div>
            </div>
            <div style={{ display: "none" }}>
              <input type="number" id="amount" name="amount" value={amount} placeholder="" />
            </div>
            <div style={{ display: "none" }}>
              <input type="text" id="email" name="email" value="test@gmail.com" placeholder="" />
            </div>
            <button type="submit" className="buyButton" style={{ width: "100%", marginTop: "20px", height: "42px" }}>
              Checkout
            </button>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              {" "}
              <LockOutlined /> Secure Checkout
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div>
      {type === "drawer" && generateCartDrawer()}
      {type === "full" && generateCartFull()}
    </div>
  );
}
