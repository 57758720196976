import axios from "axios";
import "../config/Config";

class AuthService {
  // Sign Up
  // async Login(data) {
  //   return await axios.post(`/api/api_login`, data);
  // }

  Login = async (data) => {
    // return await axios.post(`/api/api_login`, data);
    return axios
      .post(`auth/login/`, data)
      .then((response) => {
        console.log(response.data);
        console.log(response.data.data.user.username);
        if (response.data.data.status === "success") {
          console.log(response.data.data.user);
          localStorage.setItem("user", JSON.stringify(response.data.data.user));
        }
        return {username:response.data.data.user.username, id:response.data.data.user.id};
      })
      .catch((err) => {
        console.log(err);
        return null;
        // throw err;
      });
  }


  LogOut = async () => {
    return axios
      .post(`auth/logout/`)
      .then((response) => {
        console.log(response.data);
        if (response.data.data.status === "success") {
          localStorage.removeItem("user");
        }
        return response.data.data.status;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
    }
    

  // signin = async (username, password) => {
  //   return axios
  //     .post(`/auth/login`, { username: username, pwd: password })
  //     .then((response) => {
  //       if (response.data.user.access) {
  //         localStorage.setItem("user", JSON.stringify(response.data.user));
  //       }
  //       console.log(response.data + response.status);
  //       // alert(response.data.msg);
  //       return response.data;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       throw err;
  //     });
  // };

  

  // signup = async (username, password) => {
  //   return axios.post("/auth/signup", { username: username, pwd: password });
  // };

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default new AuthService();
