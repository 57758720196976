import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// styles
import "../styles/page.css";

// project
import ApiService from "../services";
import { DataTable } from "../components";

import Highlighter from "react-highlight-words";
// antd
import { UploadOutlined, SearchOutlined } from "@ant-design/icons";
import type { UploadProps, InputRef } from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import { Button, message, Upload, Input, Space, Select, Typography, Card, Divider, TreeSelect } from "antd";
import { info } from "console";
const { TextArea } = Input;

interface DataType {
  key: string;
  id: number;
  phoneNumber: string;
  sendResults: string;
}

type DataIndex = keyof DataType;

export default function MassTextPage(props: any) {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [newData, setNewData] = useState<DataType[]>([]);
  const [msg, setMsg] = useState("");
  const [hostLabel, setHostLabel] = useState("");
  const [noticePhone, setNoticePhone] = useState("61413658691");
  const [serverCSVFile, setServerCSVFile] = useState("");

  const [searchHostLabel, setSearchHostLabel] = useState("");

  const [hostList, setHostList] = useState<any>([]);

  useEffect(() => {
    ApiService.teaHostList()
      .then((response) => {
        console.log(response.data);
        setHostList([...response.data])
        setHostList(response.data.map((item:any)=>{return {
          value: item.hostNumber,
          label: item.hostNumber,
        }}))
      })
      .catch(function (error) {
        console.log(error);
        message.error(error);
      });
  },[]);

  const columns = [
    {
      title: "PhoneNumber",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: "50%",
      sorter: (a: any, b: any) => {
        return a.phoneNumber.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "SendResults",
      dataIndex: "sendResults",
      key: "sendResults",
      width: "50%",
      sorter: (a: any, b: any) => {
        return a.sendResults.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Operation",
      dataIndex: "operation",
      key: "",
      render: (text: any, record: any, index: any) => {
        return (
          <span>
            <Space>
              {/* <Typography.Link onClick={() => {console.log(record); navigate(`/scrapy/edit/${record.id}`);}} style={{ marginRight: 8 }}>
              Edit
            </Typography.Link> */}

              <Typography.Link
                onClick={() => {
                  console.log(record);
                  deleteContactHandler(record);
                }}
                style={{ marginRight: 8 }}
              >
                Delete
              </Typography.Link>
            </Space>
          </span>
        );
      },
    },
  ];

  const uploadprops: UploadProps = {
    name: "file",
    action: `${process.env.REACT_APP_SERVER}/api/teaData/`,
    multiple: false,
    accept: ".csv",
    maxCount: 1,
    onChange(info) {
      console.log(info);
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        console.log(info.file.response.file);
        setServerCSVFile(info.file.response.file)
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      setFileList([...info.fileList]);
    },
  };

  const startButtonHandler = (e: any) => {
    if (fileList.length === 0 || serverCSVFile.length === 0) {
      message.error("please upload contacts csv file");
      return;
    }
    ApiService.teaMsgSubmit({ msg: value, hostNumber: hostLabel, noticePhone: noticePhone, serverCSVFile: serverCSVFile })
      .then((response) => {
        console.log(response.data);
        message.success("Sent successfully!");
        setValue("");
        setFileList([]);
        setNewData([]);
        setMsg("");
        // setHostLabel("");
        // setServerCSVFile("");
      })
      .catch(function (error) {
        console.log(error);
        message.error(error);
      });
  };

  const searchButtonHandler = (e: any) => {
    // ApiService.teaContacts({hostNumber:searchHostLabel})
    //   .then((response) => {
    //     console.log(response.data);
    //     const tempData: any = [];
    //     let data = response.data;
    //     data?.forEach((item: any, index: number) => {
    //       const newItem = { key: index, ...item };
    //       tempData.push(newItem);
    //     });
    //     setNewData(tempData);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //     message.error(error);
    //   });
    ApiService.teaMsg({hostNumber:searchHostLabel})
      .then((response) => {
        console.log(response.data);
        let arr = response.data;
        if (arr.length > 0) {
          setMsg(arr[0].msg);
          let contactlist = arr[0].contactlist;
          const tempData: any = [];
          contactlist?.forEach((item: any, index: number) => {
            const newItem = { key: index, ...item };
            tempData.push(newItem);
          });
          setNewData(tempData);
        }
      })
      .catch(function (error) {
        console.log(error);
        message.error(error);
      });
  };

  const deleteContactHandler = (record: any) => {
    ApiService.teaDeleteContact(record.id)
      .then((response) => {
        console.log(response.data);
        const tempData = newData.filter((item) => item.key !== record.key);
        setNewData(tempData);
      })
      .catch(function (error) {
        console.log(error);
        message.error(error);
      });
  };

  
  const handleSelectChange = (value: string) => {
    console.log(`selected ${value}`);
    setHostLabel(value);
  };
  const handleSelectNoticeChange = (value: string) => {
    console.log(`selected ${value}`);
    setNoticePhone(value);
  };

  return (
    <div className="bg vlayout">
      <div>&nbsp;</div>      
      <div className="detailcontent vlayout" style={{ marginTop: "10%", marginBottom: "10%" }}>
        <div className="Title" style={{ marginBottom: "24px" }}>
          Send Group Message
        </div>
        <div style={{ margin: "20px" }}>
          Please choose the send mobile host label: &nbsp;
          <Select
            // defaultValue="Girls"
            style={{ width: 180 }}
            onChange={handleSelectChange}
            options={hostList}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Notice : &nbsp;
          <Select
            defaultValue="61413658691"
            style={{ width: 180 }}
            onChange={handleSelectNoticeChange}
            options={[
              {
                value: '61413658691',
                label: 'Tea',
              },
              {
                value: '61431464086',
                label: 'Dev',
              },
              {
                value: '61435550959',
                label: 'Boss',
              },
            ]}
          />
        </div>
        <TextArea value={value} onChange={(e) => setValue(e.target.value)} placeholder="please enter your message" autoSize={{ minRows: 5, maxRows: 10 }} />
        <div style={{ margin: "20px" }}>
          <Upload {...uploadprops} fileList={fileList}>
            <Button icon={<UploadOutlined />}>Click to Upload Contacts File (CSV File)</Button>
          </Upload>
        </div>
        <div style={{ margin: "20px" }}>
          <Space size={30}>
            <Button onClick={startButtonHandler} size="large">
              Start
            </Button>            
          </Space>
        </div>
        <Divider>Search</Divider>
        <>
          <div style={{ margin: "20px" }}>
          Please choose the send mobile host label: &nbsp;
          <Space size={30}>
            <Select
              // defaultValue="Girls"
              style={{ width: 180 }}
              onChange={ (value: string) => {setSearchHostLabel(value)}}
              options={hostList}
            />
            <Button onClick={searchButtonHandler} size="large">
              Search
            </Button>
          </Space>
        </div>
          <div style={{ margin: "20px" }}>
            <Card title="Mass Text" style={{ width: "100%" }}>
              <p>{msg}</p>
            </Card>
          </div>
          <div>
          <DataTable
            columns={columns}
            dataSource={newData}
            pagination={{ pageSize: 20 }}
            onRow={(record: any) => {
              return {};
            }}
          />
        </div>
        </>        
      </div>
    </div>
  );
}
