import React, { useEffect, useRef, ReactElement } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

// styles
import "../styles/page.css";

// project
import ApiService from "../services";
import { ImageCarousel, ImageCard, CategoryCard, GoogleMapCmp } from "../components";
import AddToCartButton from "../features/cart/AddToCartButton";
import Cart from "../features/cart/Cart";

// library files
import { useForm } from "react-hook-form";

// antd
import { LeftOutlined, RightOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { message } from "antd";

export default function ContactUs(props: any) {
  // const navigate = useNavigate();
  const {
    register,
    getFieldState,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({ mode: "onSubmit" });

  const onSubmit = (data: any) => {
    console.log(JSON.stringify(data));
    ApiService.addMsg(data).then((response) => {
      // setResult({ total: response.data.count, productList: response.data.results });
      console.log(response.data);
      message.success('Thanks for submitting!');
      // alert('submit success');
      reset();
    })
    .catch(function (error) {
      console.log(error);
      message.error(error);
    });
  };

  const onChangeForm = (data: any) => {
    // clearErrors('email');
    // console.log("onChangeForm", getFieldState("email").error?.type);
  };

  return (
    <div>
      <div className="bg">
        <div className="content vlayout">
          <div className="productTitle" style={{ marginTop: "50px" }}>
            NEED A HAND?
          </div>
          <div className="vlayout_center">
            <div className="part6_item2" style={{ lineHeight: "40px", fontWeight: "normal" }}>
              Contact Us
            </div>
            <div>
              <form className="vlayout_center contactForm" onSubmit={handleSubmit(onSubmit)} onChange={onChangeForm}>
                <div className="vlayout">
                  {/* first row */}
                  <div className="hlayout" style={{ marginBottom: "15px" }}>
                    <div className="vlayout">
                      <label className="formlabel" htmlFor="firstname" style={{ marginBottom: "8px" }}>
                        First Name *
                      </label>
                      <input
                        className="forminput leftinput" type="text" placeholder=""
                        {...register("firstname", { required: true })} 
                        aria-invalid="false" aria-required="true" maxLength={50} minLength={1}
                      />
                    </div>
                    <div className="vlayout">
                      <label className="formlabel" htmlFor="lastname" style={{ marginBottom: "8px" }}>
                      Last Name *
                      </label>
                      <input
                        className="forminput rightinput" type="text" placeholder=""
                        {...register("lastname", { required: true })} //
                        aria-invalid="false" aria-required="true" maxLength={50} minLength={1}
                      />
                    </div>
                  </div>
                  {/* second row */}
                  <div className="hlayout" style={{ marginBottom: "15px" }}>
                    <div className="vlayout">
                      <label className="formlabel" htmlFor="email" style={{ marginBottom: "8px" }}>
                      Email *
                      </label>
                      <input
                        className="forminput" type="email" placeholder=""
                        {...register("email", { required: true, pattern: /^.+@.+\.[a-zA-Z]{2,63}$/ })} 
                        aria-invalid="false" aria-required="true" maxLength={250} minLength={1}
                      />
                    </div>
                    <div className="vlayout">
                      <label className="formlabel" htmlFor="phone" style={{ marginBottom: "8px" }}>
                      Phone
                      </label>
                      <input
                        className="forminput" type="text" placeholder=""
                        {...register("phone", { required: false, pattern: /[0-9]{9,10}$/ })} //
                        aria-invalid="false" aria-required="false" maxLength={10} minLength={9}
                      />
                    </div>
                  </div>
                  {/* third row */}
                  <div className="hlayout" style={{ marginBottom: "0px" }}>
                    <div className="vlayout">
                      <label className="formlabel" htmlFor="msg" style={{ marginBottom: "8px" }}>
                      Leave us a message
                      </label>
                      <textarea className="forminput"  style={{width:"580px",height:"200px", textAlign:"left"}}
                        placeholder=""
                        {...register("msg", { required: true })} 
                        aria-invalid="false" aria-required="true" maxLength={1000} minLength={1}>
                      </textarea>
                    </div>                    
                  </div>
                  <input type="submit" value="Submit" className="contactSubmit bottominput" style={{ marginBottom: "10px" }} />
                  {/* Submit */}
                  {errors.firstname && getFieldState("firstname").error?.type === "required" && <p style={{ color: "red" }}>Please fill out your first name.</p>}

                  {errors.lastname && getFieldState("lastname").error?.type === "required" && <p style={{ color: "red" }}>Please fill out your last name.</p>}
                  
                  {errors.email && getFieldState("email").error?.type === "pattern" && <p style={{ color: "red" }}>Please enter a valid email address.</p>}
                  {errors.email && getFieldState("email").error?.type === "required" && <p style={{ color: "red" }}>Please fill out your email address.</p>}

                  {errors.phone && getFieldState("phone").error?.type === "pattern" && <p style={{ color: "red" }}>Please enter a valid phone.</p>}
                  {errors.phone && getFieldState("phone").error?.type === "required" && <p style={{ color: "red" }}>Please fill out your phone.</p>}

                  {errors.msg && getFieldState("msg").error?.type === "required" && <p style={{ color: "red" }}>Please leave us your message.</p>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
