import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// styles
import "../styles/page.css";

// project
import ApiService, { AuthService } from "../services";
import { ImageCarousel, ImageCard, CategoryCard } from "../components";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { login } from "../features/user/userSlice";
import { fetchCartAsync } from "../features/cart/cartSlice";

// library files
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";

// antd
import { LeftOutlined, RightOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Image, Button, InputNumber, Collapse } from "antd";

export default function Login(props: any) {
  //   const captchaRef = useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const captchaRef: any = React.createRef();
  const path = props.path;
  const {
    register,
    getFieldState,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({ mode: "onSubmit" });

  let title = "";
  let op_title = "";
  let linkUrl = "";
  if (path === "login") {
    title = "Log In";
    op_title = "Sign Up";
    linkUrl = "/signup";
  } else {
    title = "Sign Up";
    op_title = "Log In";
    linkUrl = "/login";
  }
  
  const onSubmit = (data: any) => {
    let token = captchaRef.current.getValue();
    console.log(token);
    captchaRef.current.reset();
    data["gtoken"] = token;
    data["type"] = path;
    //   alert(JSON.stringify(data));
    console.log(JSON.stringify(data));
    AuthService.Login(data).then((obj) => {
      console.log(obj);
      if (obj !== null) {
        dispatch(login(obj));
        dispatch(fetchCartAsync());
        navigate("/");
      }
    });
  };
  const onChangeForm = (data: any) => {
    // clearErrors('email');
    // console.log("onChangeForm", getFieldState("email").error?.type);
  };

  return (
    <div className="bg hlayout">
      <div>&nbsp;</div>
      <div className="centerScreen" style={{ marginTop: "10%", marginBottom: "10%" }}>
        <div className="Title" style={{ marginBottom: "24px" }}>
          {title}
        </div>
        <div style={{ marginBottom: "24px", textAlign: "center", fontSize: "18px" }}>
          Already a member? &nbsp;
          <Link to={linkUrl}>
            <span>{op_title}</span>
          </Link>
        </div>
        <form className="vlayout_center " style={{ width: "320px" }} onSubmit={handleSubmit(onSubmit)} onChange={onChangeForm}>
          <label className="formLabel" htmlFor="email" style={{ marginTop: "20px", marginBottom: "4px" }}>
            Email
          </label>
          <input
            className="formInput"
            type="email"
            // placeholder="Enter your email"
            {...register("email", { required: true, pattern: /^.+@.+\.[a-zA-Z]{2,63}$/ })} //
            aria-invalid="false"
            aria-required="true"
            maxLength={250}
            minLength={1}
          />
          <label className="formLabel" htmlFor="password" style={{ marginTop: "20px", marginBottom: "4px" }}>
            Password
          </label>
          <input
            className="formInput"
            type="password"
            // placeholder="Enter your email"
            {...register("password", { required: true })} //
            aria-invalid="false"
            aria-required="true"
            maxLength={250}
            minLength={8}
          />
          <div style={{ marginTop: "20px", marginBottom: "25px" }}>
            <ReCAPTCHA ref={captchaRef} sitekey={process.env.REACT_APP_SITE_KEY} theme="dark" />
          </div>
          <input type="submit" className="formSubmitButton" value={title} style={{ marginBottom: "10px" }} />
          {/* Submit
              </button> */}
          {errors.email && getFieldState("email").error?.type === "required" && <p style={{ color: "red" }}>Please fill out your email address.</p>}
          {errors.email && getFieldState("email").error?.type === "pattern" && <p style={{ color: "red" }}>Please enter a valid email address.</p>}
        </form>
      </div>
      <div>&nbsp;</div>
    </div>
  );
}
