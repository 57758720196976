import { createAsyncThunk, createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import ApiService, {AuthService} from "../../services";
import _ from "lodash";
import cookie from "react-cookies";



export interface UserState {
  status: "idle" | "loading" | "failed" | "complete";
  username: string | null;
  id: number | null;
}

const initialState: UserState = {
  status: "idle",
  username: null,
  id: null,
};

export const logoutAsync = createAsyncThunk(
  'user/logout',
  async () => {
    const response = await AuthService.LogOut();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

// export const refreshAsync = createAsyncThunk(
//   'user/refresh',
//   async () => {
//     const response = await AuthService.reLogin();
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );


export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    refreshLogin: (state) => {
      let userItem = localStorage.getItem("user");
      console.log("userItem", userItem);
      if (userItem) {
        const user = JSON.parse(userItem);
        if (user && user.username) {
          state.username = user.username;
          state.id = user.id;
          state.status = "complete";
        }
      }else{
        state.username = null;
        state.id = null;
        state.status = "complete";
      }
    },
    login: (state, action: PayloadAction<any>) => {
      state.username = action.payload.username;
      state.id = action.payload.id;
      state.status = "complete";
    },   
    logout: (state) => {
      state.username = null;
      state.id = null;
      state.status = "complete";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(logoutAsync.fulfilled, (state, action) => {
        state.status = 'complete';
        state.username = null;
        state.id = null;
      })
      .addCase(logoutAsync.rejected, (state) => {
        state.status = 'failed';
        localStorage.removeItem("user");
        state.username = null;
        state.id = null;
      });
  },
});

export const { login, refreshLogin, logout } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUsername = (state: RootState) => state.user.username;
export const selectUserID = (state: RootState) => state.user.id;
export const selectUserStatus = (state: RootState) => state.user.status;


export default userSlice.reducer;
