import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import "./styles/App.less";
import Main from "./pages/Main";
import MassTextPage from "./pages/MassTextPage";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Main path="home" />} />
          <Route path="/product/:type" element={<Main path="product" />} />
          <Route path="/bestseller/:type" element={<Main path="bestseller" />} />
          <Route path="/onsale/:type" element={<Main path="onsale" />} />
          <Route path="/productdetail/:type" element={<Main path="productdetail" />} />
          <Route path="/login" element={<Main path="login" />} />
          <Route path="/signup" element={<Main path="signup" />} />
          <Route path="/cartview" element={<Main path="cartview" />} />
          <Route path="/pay/:type" element={<Main path="pay" />} />
          <Route path="/contactUs" element={<Main path="contactUs" />} />
          <Route path="/mass" element={<MassTextPage />} />
        </Routes>
      </Router>      
    </div>
  );
}

export default App;
