import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// styles
import "../styles/page.css";
// project
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { selectIsShow, showCart } from "../features/cart/cartSlice";
import Cart from "../features/cart/Cart";
import { resetPath, selectFilter } from "../features/product/productSlice";

import withRouter, { getRoutePath, getRouteName } from "../utils/withRouter";
import HeadMenu from "../components";
import Home from "./Home";
import Product from "./Product";
import ProductDetail from "./ProductDetail";
import Login from "./Login";
import CartView from "./Cartview";
import ContactUs from "./Contactus";
import PaymentPage from "./PaymentPage";



// antd
import { RightOutlined } from "@ant-design/icons";
import { Divider, Layout, Drawer } from "antd";
const { Header, Content, Footer } = Layout;

export default function Main(props: any) {
  const dispatch = useAppDispatch();
  const isShowCart = useAppSelector(selectIsShow);
  const productFilter = useAppSelector(selectFilter);

  const { type } = useParams();
  let contentComp = <div>empty</div>;
  let position = "/";

  console.log("Main");
  if (props.path === "home") {
    // dispatch(resetPath());
    contentComp = <Home />;
  } else if (props.path === "product" || props.path === "bestseller" || props.path === "onsale") {
    // let categoryParam = type ? type: "0";
    // let categoryID = parseInt(categoryParam);
    // dispatch(ChangeFilterAsync({ path: props.path, filter: { category: categoryID } }));
    position = `/${props.path}/${type}`;
    contentComp = <Product path={props.path} type={type} />;
  } else if (props.path === "productdetail") {
    // console.log("Product detail productFilter：", productFilter);
    contentComp = <ProductDetail path={props.path} productid={type} />;
  } else if (props.path === "login" || props.path === "signup") {
    position = `/login`;
    contentComp = <Login path={props.path} />;
  } else if (props.path === "cartview") {
    position = `cart`;
    contentComp = <CartView path={props.path} />;
  } else if (props.path === "contactUs") {
    position = `/contactUs`;
    contentComp = <ContactUs path={props.path} />;
  } else if (props.path === "pay") {
    position = `/pay`;
    contentComp = <PaymentPage type={type} />;
  }
  console.log("Main", props.path, type);

  return (
    <Layout className="layout">
      <Header className="headerextended">
        <HeadMenu position={position} />
      </Header>
      <Content style={{ padding: "0 0px", margin: "0px 0px" }}>
        {/* drawer */}
        <div>
          <Drawer
            title="Cart"
            placement="right"
            mask={false}
            onClose={() => {
              dispatch(showCart(false));
            }}
            open={isShowCart}
            closeIcon={<RightOutlined style={{ fontSize: "23px", color: "black" }} />}
            headerStyle={{ backgroundColor: "white", textAlign: "center", color: "black" }}
          >
            <Cart type="drawer" />
          </Drawer>
        </div>
        <div className="site-layout-content">{contentComp}</div>
      </Content>
      <Footer style={{ textAlign: "center", backgroundColor: "black" }}>© 2023 by Creative IT Group PTY LTD</Footer>
    </Layout>
  );
}
