import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// styles
import "../styles/page.css";

// project
import ApiService from "../services";
import { ImageCarousel, ImageCard, CategoryCard } from "../components";
import AddToCartButton, {BuyNowButton} from "../features/cart/AddToCartButton";
import Cart from "../features/cart/Cart";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { selectProductStatus, selectPath, selectFilter, selectProductList, selectProductCount } from "../features/product/productSlice";
import { selectCategoryList } from "../features/category/categorySlice";

// library files
import ItemsCarousel from "react-items-carousel";
import { FacebookShareButton, FacebookIcon, PinterestShareButton, PinterestIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon } from "react-share";

// antd
import { LeftOutlined, RightOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Image, Button, InputNumber, Collapse, message } from "antd";
const { Panel } = Collapse;

export default function ProductDetail(props: any) {
  let productid = props.productid;
  let server = process.env.REACT_APP_SERVER;

  const productPath = useAppSelector(selectPath);
  const productFilter = useAppSelector(selectFilter);
  const categoryList = useAppSelector(selectCategoryList);

  const [activeItem, setActiveItem] = useState(0);
  const [load, setLoad] = useState(false);
  const [product, setProduct] = useState({ id: 0, name: "", code: "", price: "", realprice: "", delivery: 7, quantity: 1, cover: "", medias: [], infos: "", description: "" });
  const [quantity, setQuantity] = useState(1);
  
  if (!load) {
    ApiService.getProductDetails(productid)
      .then((response) => {
        setLoad(true);
        setProduct(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // 返回顶部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChangeClickHandler = (e: any) => {
    console.log(e);
    setActiveItem(e);
  };

  const generateCover = () => {
    if (activeItem === 0) {
      return <Image width={500} src={product.cover} />;
    } else {
      let media: any = product.medias[activeItem - 1];
      return <Image width={500} src={`${server}${media.img}`} />;
    }
  };

  const onImgClickHandler = (e: any) => {
    console.log(e.target.getAttribute("itemID"));
    setActiveItem(parseInt(e.target.getAttribute("itemID")));
  };

  const price = (price: any, realprice: any) => {
    if (price === realprice) {
      return <span>${realprice}</span>;
    } else {
      return (
        <span>
          <span className="deleteText">${price}</span> <span>${realprice}</span>
        </span>
      );
    }
  };

  const getCategoryName = (categoryID: number|null) => {
    let name = '';
    try {
      categoryList.forEach((category) => {
        if (category.id === categoryID) {
          name = category.name;
          throw new Error("EndIterative");
        }
      });
    } catch (e: any) {
      if (e.message != "EndIterative") throw e;
    }
    return name;
  };

  const generatePath = () => {
    let categoryID = productFilter.category === null ? 0 : productFilter.category;
    if (productPath === 'product'){
      if (categoryID === 0 ){
        return (<Breadcrumb.Item><Link to={`/product/0`}>Products</Link></Breadcrumb.Item>);
      }else{
        return (<Breadcrumb.Item><Link to={`/product/${productFilter.category}`}>{getCategoryName(productFilter.category)}</Link></Breadcrumb.Item>);
      }
    }else if(productPath === 'bestseller'){
      return (<Breadcrumb.Item><Link to={`/bestseller/${categoryID}`}>Best Sellers</Link></Breadcrumb.Item>);
    }else if(productPath === 'onsale'){
      return (<Breadcrumb.Item><Link to={`/onsale/${categoryID}`}>On Sales</Link></Breadcrumb.Item>);
    }
  };

  return (
    <div>
      {/* content */}
      <div className="bg vlayout">
        <div className="detailcontent">
          {/* Navigator */}
          <div className="hlayout" style={{ marginBottom: "50px" }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={`/`}>Home</Link>
              </Breadcrumb.Item>
              { productPath && generatePath() }
              <Breadcrumb.Item>{product.name}</Breadcrumb.Item>
            </Breadcrumb>
            <div>
              {/* <span className="preNextButton disable">
                {" "}
                <LeftOutlined /> Prev{" "}
              </span>{" "}
              |{" "}
              <span className="preNextButton">
                {" "}
                Next <RightOutlined />{" "}
              </span> */}
            </div>
          </div>
          {/* content */}
          <div className="hlayout">
            <div className="vlayout_center" style={{ marginRight: "15px" }}>
              {/* <Image width={500} src={product.cover} /> */}
              {generateCover()}
              <span> &nbsp;</span>
              <div style={{ width: "420px", marginBottom: "40px" }}>
                <ItemsCarousel
                  requestToChangeActive={onChangeClickHandler}
                  activeItemIndex={activeItem}
                  numberOfCards={7}
                  gutter={5}
                  leftChevron={<LeftOutlined className="chevronIconSmall" />}
                  rightChevron={<RightOutlined className="chevronIconSmall" />}
                  chevronWidth={50}
                  outsideChevron={true}
                  alwaysShowChevrons={false}
                >
                  <img width={43} onClick={onImgClickHandler} key={0} itemID="0" height={43} src={product.cover} />
                  {product.medias.map((item: any, index) => {
                    return <img onClick={onImgClickHandler} key={index} itemID={`${index + 1}`} width={43} height={43} src={`${server}${item.img}`} />;
                  })}
                </ItemsCarousel>
              </div>
              {/* <div>{product.description}</div> */}
              <div><p dangerouslySetInnerHTML={{__html: product.description}}></p></div>
            </div>
            <div className="vlayout_start" style={{ marginLeft: "15px" }}>
              <div className="categoryTitle">{product.name}</div>
              <div style={{ fontSize: "12px" }}>{product.code}</div>
              <div style={{ fontSize: "22px", fontWeight: "bold", marginTop: "23px" }}>{price(product.price, product.realprice)}</div>
              <div style={{ fontSize: "18px", marginTop: "23px" }}>{product.delivery}</div>
              <div style={{ fontSize: "18px", fontWeight: "bold", marginTop: "20px" }}>Quantity</div>
              <div style={{ fontSize: "18px", fontWeight: "bold", marginTop: "20px" }}>
                <InputNumber
                  size="large"
                  min={1}
                  max={product.quantity}
                  defaultValue={quantity}
                  onChange={(value) => {
                    // console.log("value: " + value);
                    // if  (value > product.quantity) {
                    //   value = product.quantity;
                    //   message.warning(`Only ${product.quantity} available`);
                    // }
                    setQuantity(value);
                  }}
                />
              </div>
              <div style={{ marginTop: "20px", width: "80%" }}>
                {/* <button className="buyButton" style={{ width: "100%" }}>
                Add to Cart
              </button> */}
                <AddToCartButton                  
                  itemInfo={{ product: product.id, name: product.name, price: product.price, realprice: product.realprice, cover: product.cover, quantity: quantity, quantityMax: product.quantity}}
                />
                <BuyNowButton                  
                  itemInfo={{ product: product.id, name: product.name, price: product.price, realprice: product.realprice, cover: product.cover, quantity: quantity, quantityMax: product.quantity}}
                />
              </div>
              {/* <div className="filterItem">
                <Collapse
                  defaultActiveKey={["1"]}
                  ghost
                  expandIconPosition="end"
                  expandIcon={({ isActive }) => (isActive ? <MinusOutlined style={{ color: "white", fontSize: "18px" }} /> : <PlusOutlined style={{ color: "white", fontSize: "18px" }} />)}
                >
                  <Panel header={<span className="filterlistTitle">Product Info</span>} key="1">
                    <div style={{ fontSize: "14px" }}>{product.infos}</div>
                  </Panel>
                </Collapse>
              </div> */}
              <div className="filterItem">
                <Collapse
                  defaultActiveKey={["1"]}
                  ghost
                  expandIconPosition="end"
                  expandIcon={({ isActive }) => (isActive ? <MinusOutlined style={{ color: "white", fontSize: "18px" }} /> : <PlusOutlined style={{ color: "white", fontSize: "18px" }} />)}
                >
                  <Panel header={<span className="filterlistTitle">Postage:</span>} key="1">
                    <div style={{ fontSize: "14px" }}>
                    FREE Standard postage
                    </div>
                  </Panel>
                </Collapse>
              </div>
              <div className="filterItem">
                <Collapse
                  defaultActiveKey={["1"]}
                  ghost
                  expandIconPosition="end"
                  expandIcon={({ isActive }) => (isActive ? <MinusOutlined style={{ color: "white", fontSize: "18px" }} /> : <PlusOutlined style={{ color: "white", fontSize: "18px" }} />)}
                >
                  <Panel header={<span className="filterlistTitle">Delivery:</span>} key="1">
                    <div style={{ fontSize: "14px" }}>
                    Estimated delivered in {product.delivery} days
                    </div>
                  </Panel>
                </Collapse>
              </div>
              <div style={{ marginTop: "40px" }}>
                <WhatsappShareButton url={window.location.href} title={product.name} separator=":: " className="share_button">
                  <WhatsappIcon size={28} round />
                </WhatsappShareButton>
                <FacebookShareButton url={window.location.href} quote={product.name} className="share_button">
                  <FacebookIcon size={28} round />
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href} title={product.name} className="share_button">
                  <TwitterIcon size={28} round />
                </TwitterShareButton>
                <PinterestShareButton url={window.location.href} media={product.cover} className="share_button">
                  <PinterestIcon size={28} round />
                </PinterestShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
