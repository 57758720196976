import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// styles
import "../../styles/page.css";

// project
import ApiService from "../../services";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectItems, selectStatus, selectAmount, selectIsShow, selectOrderID, selectDetails, selectAddress } from "./cartSlice";
import { selectUsername, selectUserID } from "../user/userSlice";

// antd
import { Button, Checkbox, Form, Input, Divider } from "antd";
import { CloseOutlined, LockOutlined } from "@ant-design/icons";

let PaymentCmp: any = (props: any, refForm: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const username = useAppSelector(selectUsername);
  const userID = useAppSelector(selectUserID);
  const cartItems = useAppSelector(selectItems);
  const amount = useAppSelector(selectAmount);
  const details = useAppSelector(selectDetails);
  const address = useAppSelector(selectAddress);

  const formRef: any = useRef<HTMLFormElement>();
  const submitHandler = (e: any) => {
    // e.preventDefault();
    const orderData = {
      firstname: details?.firstname,
      lastname: details?.lastname,
      phone: details?.phone,
      email: details?.email,
      address: address?.addrStr,
      address_split: address?.addrJson,
      items: cartItems,
      amount: amount,
    };
    ApiService.orderSubmit(orderData)
      .then((response) => {
        formRef.current.order_id.value = response.data.id;
        formRef.current.email.value = username;
        formRef.current.userid.value = userID;
        formRef.current.submit();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="vlayout " style={{ width: "500px", margin: "30px auto" }}>
      <div style={{ fontSize: "28px", marginTop: "50px" }}>Checkout</div>
      <div style={{ margin: "30px auto", width: "100%" }}>
        {/* <form action={`https://cyberpunk2077.store/api/payment_1/`} method="POST"> */}
        <form ref={formRef} action={`${process.env.REACT_APP_SERVER}/api/payment_2/`} method="POST">
          <div style={{ fontSize: "20px" }}>Order summary</div>
          <Divider style={{ borderTopColor: "#999999" }} />
          <div className="hlayout">
            <div style={{ fontSize: "16px" }}>Subtotal</div>
            <div style={{ fontSize: "16px" }}>${amount}</div>
          </div>
          <div className="hlayout" style={{ marginTop: "16px" }}>
            <div style={{ fontSize: "16px" }}>Shipping</div>
            <div style={{ fontSize: "16px" }}>FREE</div>
          </div>
          <Divider style={{ borderTopColor: "#999999" }} />
          <div className="hlayout" style={{ marginTop: "16px" }}>
            <div style={{ fontSize: "20px" }}>Total</div>
            <div style={{ fontSize: "20px" }}>${amount}</div>
          </div>
          <div style={{ display: "none" }}>
            <input type="number" id="amount" name="amount" value={amount} placeholder="" />
            <input type="text" id="email" name="email" value="" placeholder="" />
            <input type="text" id="userid" name="userid" value="-1" placeholder="" />
            <input type="text" id="order_id" name="order_id" value="" placeholder="" />
          </div>
        </form>
        <button onClick={submitHandler} className="buyButton" style={{ width: "100%", marginTop: "40px", height: "42px" }}>
          Checkout
        </button>
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          {" "}
          <LockOutlined /> Secure Checkout
        </div>
      </div>
    </div>
  );
};

export default PaymentCmp;
